<template>
  <v-card
    text
    elevation="0"
    class="pending-project-container"
  >
    <v-card-text>
      <v-row>
        <v-col cols="7">
          <v-text-field
            ref="title"
            v-model="project.title"
            :rules="[rules.required]"
            :disabled="hasSubmitIntention"
            clearable
            class="mb-2 mt-2"
            label="Title *"
            placeholder="Enter a title..."
            required
          />
          <v-text-field
            ref="tagline"
            v-model="project.tagline"
            :rules="[rules.required, rules.textExceededCharacters(project.tagline, 60)]"
            :disabled="hasSubmitIntention"
            :counter="60"
            label="Tagline *"
            clearable
            class="mb-2"
            placeholder="Enter a tagline..."
            required
          />
          <label class="grey--text text--darken-1">Description *</label>
          <editor
            ref="description"
            class="mt-1 mb-5"
            :content="project.description"
            :disabled="hasSubmitIntention"
            :show-submit-button="false"
            :required="true"
            toolbar="minimalToolbar"
            @keyUp="project.description = $event"
          />

          <v-text-field
            v-if="company.features[featureNames.REQUESTED_STARTING_MONTH]"
            disabled
            label="Requested Starting Month"
            :value="project.startMonth.format('MMMM YYYY')"
          />
          <v-text-field
            disabled
            class="mb-2"
            label="Creator"
            :value="form.creator.name"
          />
          <v-text-field
            disabled
            class="mb-2"
            label="Creation date"
            :value="creationDate"
          />
          <v-text-field
            disabled
            label="Creator Email"
            :value="creatorEmail"
          />
          <change-owner-option
            :project="project"
            @click="showChangeOwnerDialog = true"
          />
          <change-owner
            v-if="showChangeOwnerDialog"
            :show="showChangeOwnerDialog"
            :project="project"
            @confirm="ownerChanged"
            @close="showChangeOwnerDialog = false"
          />
          <v-text-field
            v-if="form.projectUnit"
            disabled
            class="mb-2"
            label="Project Unit"
            :value="form.projectUnit.name"
          />
          <v-autocomplete
            v-if="company.features[featureNames.CAMPAIGNS]"
            ref="campaign"
            v-model="project.campaign"
            v-ruid="'campaign'"
            class="mb-2"
            clearable
            :disabled="hasSubmitIntention"
            :items="campaigns"
            item-text="title"
            label="Campaign"
            placeholder="Select a campaign..."
            return-object
          />
        </v-col>
        <v-col
          cols="4"
          offset="1"
        >
          <select-image
            ref="selectImage"
            :initial-image="initialImage"
            :show-remove-button="false"
            :disabled="true"
            :width="250"
            :height="120"
            placeholder="No image"
          />
        </v-col>
        <v-col
          v-if="teamMemberFeature"
          cols="12"
          sm="7"
        >
          <team-member-list :items="form.teamMembers" />
        </v-col>
        <v-col cols="7">
          <label
            class="d-block mb-2"
            :class="{ 'error--text': videoError }"
          >
            1 Minute Video
          </label>
          <video-upload
            ref="video1min"
            :disabled="hasSubmitIntention"
            placeholder="Enter a url"
            :storage-key="videoStorageKey"
            :url="videoLink"
            @change="videoLink = $event"
            @progress="onVideoUploadProgress"
            @validate="videoError = $event"
          >
            <app-button
              v-ruid="'defaultVideo'"
              :class="{'mt-4': videoLink, 'ml-4': !videoLink}"
              :disabled="hasSubmitIntention"
              secondary
              @click="videoLink = defaultVideoLink"
            >
              Default Video
            </app-button>
          </video-upload>
        </v-col>
        <v-col
          cols="10"
          md="6"
        >
          <video-display
            v-ruid="'video1min'"
            edit-mode
            :storage-key="videoStorageKey"
            :url="videoLink"
          />
        </v-col>
        <v-col
          cols="7"
          class="py-0"
        >
          <coaching-languages v-model="project.coachingLanguages" />
        </v-col>
        <v-col cols="7">
          <select-users
            v-model="project.coaches"
            v-ruid="'coaches'"
            :disabled="hasSubmitIntention"
            :source="allCoaches"
            show-admin-roles
          />
        </v-col>

        <v-col cols="12">
          <app-button
            secondary
            :to="{ name: 'AdminManageProjectNotes', params: { id: project.id } }"
          >
            Add or manage notes
          </app-button>
        </v-col>

        <v-col
          class="py-0"
          cols="12"
        >
          <v-checkbox
            v-model="hasDeleteIntention"
            class="mb-8"
            color="error"
            :disabled="hasSubmitIntention"
            hint="Once the project is deleted, it cannot be restored"
            label="Delete Project"
            persistent-hint
          />
        </v-col>
        <v-col
          v-if="project.isApproved"
          class="py-0"
          cols="12"
        >
          <v-checkbox
            v-model="isAlumniChecked"
            class="alumni mt-2 mb-8"
            color="primary"
            :disabled="hasSubmitIntention"
            hide-details
            label="Alumni Project"
          />
        </v-col>
        <v-col
          class="pr-1 py-0"
          cols="12"
        >
          <app-button
            v-ruid="'saveButton'"
            :color="hasDeleteIntention ? 'error' : null"
            :disabled="hasSubmitIntention"
            :loading="hasSubmitIntention && !(
              cachedIntent.approveProject || cachedIntent.startProject || cachedIntent.rejectProject
            )"
            @click="saveProject"
          >
            {{ hasDeleteIntention ? 'Delete' : 'Save' }}
          </app-button>

          <template v-if="project.isApproved && project.creator.active && !hasDeleteIntention">
            <app-button
              v-ruid="'startButton'"
              class="ml-4"
              :disabled="hasSubmitIntention || isAlumniChecked"
              :loading="cachedIntent.startProject"
              @click="openApproveDialog"
            >
              Start Project
            </app-button>
            <app-button
              class="ml-4"
              color="error"
              :disabled="hasSubmitIntention || isAlumniChecked"
              :loading="cachedIntent.unapprove"
              @click="openUnapproveDialog"
            >
              Unapprove
            </app-button>
          </template>
        </v-col>
        <label class="mt-4">* Mandatory field</label>

        <v-col
          v-if="!project.isApproved && project.creator.active && !hasDeleteIntention"
          cols="12"
        >
          <p class="title mb-1 mt-10">
            Approve Project
          </p>
          <v-divider class="mb-6" />
          <app-button
            v-ruid="'rejectButton'"
            cancel
            :disabled="hasSubmitIntention"
            :loading="cachedIntent.rejectProject"
            secondary
            @click="openRejectDialog"
          >
            Reject
          </app-button>
          <app-button
            v-ruid="'approveButton'"
            class="ml-4"
            :disabled="hasSubmitIntention"
            :loading="cachedIntent.approveProject"
            outlined
            @click="openApproveDialog"
          >
            Approve
          </app-button>
        </v-col>
      </v-row>
    </v-card-text>
    <confirm-dialog
      :show="showDeleteDialog"
      :confirm-click="deleteProject"
      @close="showDeleteDialog = false"
    >
      Are you sure you want to delete this project?
      This action cannot be undone.
    </confirm-dialog>
    <approve-dialog
      v-if="renderApproveDialog"
      v-model="showApproveDialog"
      :project-id="project.id"
      :video-link="videoLink"
      :video-storage-key="videoStorageKey"
      @submit="saveProject"
    />
    <base-content-dialog
      v-if="showRejectDialog"
      :show-dialog="showRejectDialog"
      :user-language="project.creator.language"
      title="Rejection Project Message"
      detail-message="*This message will be added to the rejection email
       and will show up under the coaching notes section for the admins."
      content-page="PROJECT_REJECT_MESSAGE"
      @close-dialog="showRejectDialog = false"
      @send-message="saveProject({ rejectProject: true, rejectMessage: $event })"
    />
    <base-content-dialog
      v-if="showUnapproveDialog"
      :show-dialog="showUnapproveDialog"
      :user-language="project.creator.language"
      title="Unapprove Project Message"
      detail-message="*This message will be added to the unapprove email."
      content-page="PROJECT_UNAPPROVE_MESSAGE"
      @close-dialog="showUnapproveDialog = false"
      @send-message="saveProject({ unapproveProject: true, unapproveMessage: $event })"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ConfirmDialog, ValidateMixin, SelectImage, SelectUsers, Editor } from '@kickbox/common-admin';
import { VideoDisplay } from '@kickbox/common-components';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import { DEFAULT_PROJECT_VIDEO } from '@kickbox/common-util/constants/video';
import videoMixin from '@kickbox/common-util/src/mixins/video';
import Parse from '@kickbox/common-util/src/parse';
import { REDBOX_IDEA } from '@kickbox/common-util';
import Moment from 'moment';
import VideoUpload from '@/components/widgets/VideoUpload';
import ApproveDialog from '@/components/projects/ApproveDialog';
import BaseContentDialog from '@/components/projects/BaseContentDialog';
import projectService from '@/services/projectService';
import roleService from '@/services/roleService';
import TeamMemberList from './TeamMemberList';
import CoachingLanguages from './CoachingLanguages';
import ChangeOwnerOption from '@/components/projects/ChangeOwnerOption';
import ChangeOwner from './ChangeOwner';
import { userService } from '@/services';

export default {
  name: 'PendingProject',
  components: {
    ApproveDialog,
    ConfirmDialog,
    BaseContentDialog,
    SelectUsers,
    TeamMemberList,
    VideoDisplay,
    VideoUpload,
    SelectImage,
    Editor,
    CoachingLanguages,
    ChangeOwner,
    ChangeOwnerOption
  },
  mixins: [ValidateMixin],
  props: ['project'],
  data() {
    return {
      featureNames: FEATURE_NAME,

      // Project video
      videoLink: null,
      videoError: null,
      videoUploadProgress: { segments: 0, uploaded: 0 },
      defaultVideoLink: null,

      // Dialogs
      renderApproveDialog: false,
      showApproveDialog: false,
      showDeleteDialog: false,
      showRejectDialog: false,
      showUnapproveDialog: false,
      showChangeOwnerDialog: false,

      // Intents
      hasDeleteIntention: false,
      hasSubmitIntention: false,
      cachedIntent: {},
      isAlumniChecked: false,
      form: {
        creator: {},
        teamMembers: [],
        projectUnit: {
          name: 'no unit'
        }
      }
    };
  },
  computed: {
    ...mapGetters(['campaigns', 'company', 'allCoaches']),

    /**
     * Returns the mandatory inputs and their values.
     */
    formMandatoryFields() {
      return {
        title: this.project.title,
        tagline: this.project.tagline,
        description: this.project.description
      };
    },

    /**
     * Checks whether the project creator's email is verified.
     *
     * @returns {boolean}
     */
    isEmailVerified() {
      return this.project.parseObject.get('parent').get('emailVerified');
    },

    /**
     * Checks whether a video upload is in progress.
     *
     * @returns {boolean}
     */
    isUploading() {
      return (
        this.videoUploadProgress.segments
        && this.videoUploadProgress.uploaded < this.videoUploadProgress.segments
      );
    },

    /**
     * Returns the storage key of the one minute video.
     *
     * @returns {string}
     */
    videoStorageKey() {
      return this.videoLink === this.defaultVideoLink
        ? DEFAULT_PROJECT_VIDEO
        : `videos/project-${this.project.id}-oneMinute`;
    },
    creationDate() {
      return new Moment(this.project.createdAt).format('MMM DD, YYYY');
    },
    creatorEmail() {
      const { email } = this.form.creator;
      if (!email || email.includes('deleted@kickbox.com')) {
        return 'Deleted User';
      }
      return this.form.creator.email;
    },
    teamMemberFeature() {
      return this.company.features[this.featureNames.TEAM_MEMBERS];
    }
  },

  watch: {
    /**
     * Unrenders the approve dialog 300ms after closing.
     *
     * This ensures that the closing transition plays and the dialog is reset properly.
     *
     * @param {boolean} willBeOpen Whether the dialog is being opened.
     */
    showApproveDialog(willBeOpen) {
      if (!willBeOpen) setTimeout(() => { this.renderApproveDialog = false; }, 300);
    }
  },

  async created() {
    this.initialImage = this.project.photo;
    this.isAlumniChecked = this.project.isAlumni;
    this.form.creator = this.project.creator;
    this.form.projectUnit = this.project.projectUnit;
    this.form.teamMembers = await projectService.getTeamMembersForProject(this.project.id) || [];
  },

  async mounted() {
    window.addEventListener('beforeunload', (e) => {
      const message = 'Upload is in progress... Do you want to cancel it?';
      // eslint-disable-next-line no-alert
      if (this.isUploading && !window.confirm(message)) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    });

    // Get default video URL
    this.defaultVideoLink = await Parse.Cloud.run(
      'getFileUrl',
      { key: DEFAULT_PROJECT_VIDEO }
    );

    // Set project video
    this.videoLink = this.project.videos.redboxPitch;
  },

  methods: {
    ...mapMutations(['updateProject']),

    /**
     * Warns the user that if an upload is running when they try to leave the page.
     */
    beforeRouteLeave(to, from, next) {
      const message = 'Upload is in progress... Do you want to cancel it?';
      if (this.isUploading) {
        // eslint-disable-next-line no-alert
        if (!window.confirm(message)) {
          next(false);
          return;
        }

        this.hasSubmitIntention = false;

        if (this.$refs.video1min) {
          // always null check to prevent error on page refresh
          this.$refs.video1min.cancelUpload();
        }
      }
      next();
    },

    /**
     * Deletes the project.
     */
    deleteProject() {
      this.showDeleteDialog = false;
      this.hasSubmitIntention = true;

      // Cancel video upload
      if (this.isUploading && this.$refs.video1min) {
        this.$refs.video1min.cancelUpload();
      }

      projectService.deleteProject(this.project.id);
    },

    /**
     * Checks if all requirements for approval are met and opens approve dialog.
     */
    openApproveDialog() {
      // Validate mandatory input field values
      if (!this.validateMandatoryFields()) return;

      // Validate creator email address
      if (!this.isEmailVerified) {
        this.$store.dispatch('showSnackBar', { text: 'The project creator must verify their email address!' });
        return;
      }

      // Wait for finished video upload
      if (this.isUploading) {
        this.$store.dispatch('showSnackBar', { text: 'Waiting for video upload to complete.' });
        return;
      }

      // Show dialog
      this.renderApproveDialog = true;
      this.showApproveDialog = true;
    },
    /**
     * Opens the reject dialog if all required fields are filled.
     */
    openRejectDialog() {
      // Validate mandatory input field values
      if (!this.validateMandatoryFields()) return;

      // Show dialog
      this.showRejectDialog = true;
    },
    openUnapproveDialog() {
      // Validate mandatory input field values
      if (!this.validateMandatoryFields()) return;

      // Show dialog
      this.showUnapproveDialog = true;
    },

    /**
     * Saves the project.
     *
     * Depending on the set intentions, the project is also approved, rejected or started.
     *
     * @param {Object} [intent] Cointainer for approve / reject / start intent data.
     *
     * @param {boolean} [intent.approveProject] Whether the project should be approved.
     * @param {string}  [intent.approveMessage] The approval message to send to the project creator.
     *
     * @param {boolean} [intent.startProject]          Whether the project should be started.
     * @param {number}  [intent.startCoins]            The amount of coins to award to the project.
     * @param {Object}  [intent.emailCampaign]         The email campaign settings for the project.
     * @param {boolean} intent.emailCampaign.enabled   Whether to subscribe to an email campaign.
     * @param {string}  intent.emailCampaign.startDate The start date of the email campaign.
     *
     * @param {boolean} [intent.rejectProject] Whether the project should be rejected.
     * @param {string}  [intent.rejectMessage] The rejection message to send to the project creator.
     */
    async saveProject(intent = {}) {
      // Show delete confirmation dialog if "Delete Project" is checked
      if (this.hasDeleteIntention) {
        this.showDeleteDialog = true;
        return;
      }

      // Validate mandatory input field values
      if (!this.validateMandatoryFields()) return;

      this.showApproveDialog = false;
      this.showRejectDialog = false;
      this.showUnapproveDialog = false;
      this.cachedIntent = intent;
      this.hasSubmitIntention = true;

      // Wait for video upload to finish
      if (this.isUploading) {
        this.$store.dispatch(
          'showSnackbar',
          { text: 'Changes will be saved as soon as the video upload is completed.' }
        );
        return;
      }

      try {
        // Save uploaded video
        this.videoLink = await this.$refs.video1min.save();

        // Set updated project properties
        const updateData = {
          title: this.project.title,
          tagline: this.project.tagline,
          description: this.project.description,
          isAlumni: this.isAlumniChecked,
          video1min: videoMixin.methods.isYoutubeVideo(this.videoLink)
            ? this.videoLink
            : this.videoLink.split('?')[0],
          coaches: this.project.coaches,
          coachingLanguages: this.project.coachingLanguages
        };
        const unsetData = [];
        let successMessage = 'The project was saved successfully.';

        // Set or unset campaign
        if (this.project.campaign) updateData.campaign = this.project.campaign;
        else unsetData.push('campaign');

        if (this.isAlumniChecked !== this.project.isAlumni) {
          await projectService
            .manageAlumni(this.project, this.isAlumniChecked && !this.project.isAlumni);
        }

        // Mark project as approved
        if (intent.approveProject) {
          updateData.isApproved = true;
          successMessage = 'The project was approved successfully.';
        }

        // Mark project as rejected
        if (intent.rejectProject) {
          updateData.isRejected = true;
          successMessage = (
            'The project was rejected successfully.'
            + ' You can find it under Projects -> Rejected.'
          );
        }

        // Mark project as unapproved
        if (intent.unapproveProject) {
          updateData.isApproved = false;
          successMessage = ('The project was unapproved successfully.');
        }

        // Update project data in database
        await projectService.updateProject(
          this.project.id,
          updateData,
          unsetData
        );

        // Start project
        if (intent.startProject) {
          // Move project to Redbox: Idea phase
          await projectService.changeProjectPhase(REDBOX_IDEA.name, this.project);

          // Add Kickboxer role to project creator
          roleService.addKickBoxerRole(this.project.creator.email);

          // Add coins to project bank account
          if (this.company.features[FEATURE_NAME.COINS] && intent.startCoins) {
            projectService.addCreditToProject(this.project.bankAccount.id, intent.startCoins);
          }

          // Subscribe to email campaign
          if (intent.emailCampaign.enabled) {
            await projectService.subscribeToEmailCampaign(
              this.project.id,
              this.project.creator.email,
              {
                date: intent.emailCampaign.startDate,
                coachingNumber: 1
              }
            );
          }

          successMessage = (
            `The project was ${intent.startProject ? 'approved' : 'started'} successfully.`
            + ' You can find it under Projects -> Manage.'
          );
        }

        // Send approved / rejected email
        if (intent.approveProject) {
          projectService.sendEmailApprovedProject(this.project, intent.approveMessage);
        } else if (intent.rejectProject) {
          projectService.sendEmailRejectedProject(this.project, intent.rejectMessage);
          await projectService.fetchRejectedProjects();
        } else if (intent.unapproveProject) {
          projectService.sendUnapprovedProjectEmail(this.project, intent.unapproveMessage);
        }

        // Remove project from store if user doesn't have access to it anymore
        if (!await projectService.canIModifyProject(this.project.id)) {
          this.$store.commit('removeProject', this.project);
        } else {
          // Otherwise update the project in store
          this.$store.commit(
            'updateProject',
            await projectService.fetchProjectById(this.project.id)
          );
        }

        this.$store.dispatch('showSnackBar', { text: successMessage });
      } catch (error) {
        this.$store.dispatch('showSnackBar', { text: 'Something went wrong!' });
        console.error(error);
      } finally {
        // Cleanup form
        this.hasSubmitIntention = false;
        this.cachedIntent = {};
      }
    },

    /**
     * Checks whether all mandatory fields are filled.
     *
     * @returns {boolean}
     */
    validateMandatoryFields() {
      if (this.checkFields()) return true;

      this.$store.dispatch('showSnackBar', { text: 'Please fill all mandatory fields' });
      return false;
    },

    /**
     * Handle video upload progress.
     *
     * If the upload is completed and the page is waiting to save the project, the saving process
     * is triggered.
     *
     * @param {Object} event          The upload progress event object.
     * @param {number} event.segments The total amount of upload segments.
     * @param {number} event.uploaded The uploaded amount of upload segments.
     */
    onVideoUploadProgress(event) {
      this.videoUploadProgress = event;

      if (this.hasSubmitIntention) this.saveProject(this.cachedIntent);
      this.$emit('idle', !this.isUploading);
    },
    async ownerChanged(project) {
      this.showChangeOwnerDialog = false;
      const projectProperties = await userService.updateChangeOwnerStore(project);
      this.form = { ...this.form, ...projectProperties };
    }
  }
};
</script>

<style lang="scss">
.team-members-container {
  margin-bottom: 20px;
  .tm-label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .tm-item {
    display: flex;
    .tm-item-col {
      margin-right: 20px;
    }
  }
}

label {
  font-size: 12px;
}
</style>
