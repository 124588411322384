<template>
  <v-dialog
    v-model="value"
    max-width="800"
    persistent
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        flat
      >
        <v-toolbar-title>{{ project.isApproved ? 'Start' : 'Approve' }} Project</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="dialog-content pt-4">
        <!-- Approve Message and Start Project Checkbox -->
        <template v-if="!project.isApproved">
          <!-- Approve Message -->
          <label>Approval Message *</label>
          <editor
            class="mt-1"
            :content="message"
            :show-submit-button="false"
            toolbar="contentToolbar"
            @keyUp="message = $event"
          />
          <label>This message will be sent to the project creator and the team members</label>

          <!-- Start Project Checkbox -->
          <v-checkbox
            v-model="startProject"
            hide-details
            label="Start the Project"
          />
          <label>Starting the project will move it to the Redbox phase.</label>
        </template>

        <v-expand-transition>
          <div v-show="project.isApproved || startProject">
            <v-divider
              v-if="!project.isApproved"
              class="my-2"
            />

            <!-- Email Campaign -->
            <email-campaign
              :email-campaign="shouldStart"
              @campaignDateSelected="emailCampaign.startDate = $event"
              @campaignSelected="emailCampaign.enabled = $event"
            />
            <!-- Coins Input -->
            <v-text-field
              v-if="company.features[featureName.COINS]"
              v-model.number="coins"
              :class="emailCampaign.enabled ? 'mt-0' : 'mt-4'"
              clearable
              label="Coins *"
              placeholder="Default: 1000"
              :rules="[
                (v) => !!v || 'This field is required.',
                (v) => Number(v) >= 0 || 'Coins cannot be lower than 0.'
              ]"
              type="number"
            />
            <!-- Redbox Pitch Display -->
            <label :class="{ 'error--text': !videoLink }">
              1 Minute Video *
            </label>
            <p
              v-if="!videoLink"
              class="error-message error--text mb-0"
            >
              You need to upload a video before starting the project!
            </p>
            <video-display
              v-else
              :storage-key="videoStorageKey"
              :url="videoLink"
              class="mt-1"
            />
          </div>
        </v-expand-transition>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <app-button
          color="error"
          secondary
          @click="$emit('input', false)"
        >
          Cancel
        </app-button>
        <v-spacer />
        <app-button
          :disabled="shouldStart ? !canStart : !canApprove"
          @click="submit"
        >
          {{ buttonText }}
        </app-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ContentService, Editor } from '@kickbox/common-admin';
import { VideoDisplay } from '@kickbox/common-components';
import DEFAULT_CONTENT from '@kickbox/common-util/constants/default-content';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';

import EmailCampaign from '@/components/projects/EmailCampaign';

export default {
  components: { Editor, EmailCampaign, VideoDisplay },

  props: {
    /**
     * The ID of the project to approve.
     */
    projectId: {
      type: String,
      required: true
    },

    /**
     * The link of the 1 minute video on the page.
     */
    videoLink: {
      type: String,
      default: null
    },

    /**
     * The storage key of the 1 minute video on the page.
     */
    videoStorageKey: {
      type: String,
      default: null
    },

    /**
     * Controls the visibility of the dialog.
     */
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      featureName: FEATURE_NAME,

      message: null,
      defaultMessage: null,
      startProject: false,
      coins: 1000,
      emailCampaign: {
        enabled: false,
        startDate: new Date().toISOString().substr(0, 10)
      }
    };
  },

  computed: {
    /**
     * Builds the Approve / Start button text.
     *
     * @returns {string}
     */
    buttonText() {
      const parts = [];

      if (!this.project.isApproved) parts.push('Approve');
      if (this.shouldStart) parts.push('Start Project');

      return parts.join(' & ');
    },

    /**
     * Decides whether the project approval requirements are met.
     *
     * @returns {boolean}
     */
    canApprove() {
      return this.project.isApproved || !!this.message;
    },

    /**
     * Decides whether the project start requirements are met.
     *
     * @returns {boolean}
     */
    canStart() {
      let canStart = this.canApprove;

      // Check Redbox Pitch
      canStart = canStart && !!this.videoLink;

      // Check Coins (if enabled)
      if (this.company.features[FEATURE_NAME.COINS]) {
        canStart = canStart && this.coins && Number(this.coins) >= 0;
      }

      return canStart;
    },

    /**
     * The company of the current user.
     *
     * @returns {Object}
     */
    company() {
      return this.$store.getters.company;
    },

    /**
     * The project to approve / start.
     *
     * @returns {Object}
     */
    project() {
      return this.$store.getters.projectById(this.projectId);
    },

    /**
     * Check whether the project should be started when submitting the dialog.
     *
     * @returns {boolean}
     */
    shouldStart() {
      return this.project.isApproved || this.startProject;
    }
  },

  async created() {
    // Load and set default approve message
    const content = await ContentService.getContent(
      DEFAULT_CONTENT.PAGES.PROJECT_APPROVE_MESSAGE.key,
      this.company.parseObject,
      this.project.creator.language
    );

    this.defaultMessage = content?.text || null;
    this.message = this.defaultMessage;
  },

  methods: {
    /**
     * Submits the project for approval.
     */
    submit() {
      this.$emit('submit', {
        approveProject: !this.project.isApproved,
        approveMessage: this.message,

        startProject: this.shouldStart,
        startCoins: this.coins,
        emailCampaign: this.emailCampaign
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#video-upload {
  ::v-deep .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }

  ::v-deep .v-input--selection-controls {
    margin-top: 0;
  }
}

.dialog-content {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
}

::v-deep .video-info-bubble {
  margin-right: 0;
  margin-top: 0;
}

label, .error-message {
  font-size: 12px;
}

::v-deep .ql-editor{
  height: 100px;
}

</style>
