<template>
  <v-container>
    <v-card class="pa-10">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
        <v-spacer />
        <download-excel
          :data="searchResult"
          :fields="exportDataFields"
          :meta="[]"
          name="download.xls"
        >
          <app-button
            secondary
            :disabled="searchResult.length === 0"
            class="mt-4 mt-md-0"
          >
            Export results
          </app-button>
        </download-excel>
      </v-card-title>

      <unit-filter />

      <v-data-table
        ref="projectTable"
        :headers="headers"
        :items="projectsFilteredByUnit(pendingProjects)"
        :search="search"
        :custom-filter="customFilter"
        :custom-sort="customSort"
        :no-data-text="noData"
        :no-results-text="noSearchResultMessage"
        single-expand
        class="table-container"
        :footer-props="{
          itemsPerPageText: 'Projects per page:',
          itemsPerPageOptions: [12, 24, 36, { text: 'All', value: -1 }]
        }"
      >
        <template
          v-if="!pendingProjects.length && isLoading.projects"
          #body
        >
          <table-skeleton-loader :headers="headers" />
        </template>
        <template
          v-else
          #item="{ item, expand, isExpanded }"
        >
          <tr
            :class="{ disabled: !idle }"
            @click="onClickRow(isExpanded, expand)"
          >
            <td>{{ getProjectStatus(item) }}</td>
            <td>{{ item.createdAt | formatDate }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.notes.length }}</td>
            <td>{{ item.coachNames }}</td>
            <td>{{ item.creator.name }}</td>
            <td>
              <div v-if="item.projectUnit">
                {{ item.projectUnit.name }}
              </div>
            </td>
            <td v-if="company.features[FEATURE_NAME.CAMPAIGNS]">
              {{ (item.campaign && item.campaign.title) || 'no campaign' }}
            </td>
          </tr>
        </template>
        <template
          #expanded-item="{ item }"
        >
          <tr class="expanded">
            <td
              colspan="7"
              class="pa-0"
            >
              <pending-project
                ref="project"
                :project="item"
                @idle="idle = $event; headers.forEach(h => h.sortable = $event);"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { TableSkeletonLoader } from '@kickbox/common-admin';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import searchFiltersMixin from '@/mixins/searchFiltersMixin';
import PendingProject from './PendingProject';
import UnitFilter from './UnitFilter';

export default {
  name: 'PendingProjects',
  components: {
    PendingProject,
    UnitFilter,
    TableSkeletonLoader
  },
  mixins: [searchFiltersMixin],
  beforeRouteLeave(to, from, next) {
    if (this.$refs.project) {
      this.$refs.project.beforeRouteLeave(to, from, next);
    } else {
      next();
    }
  },
  props: ['projectId'],
  data() {
    return {
      idle: true,
      FEATURE_NAME
    };
  },
  computed: {
    ...mapGetters([
      'pendingProjects',
      'company',
      'isLoading',
    ]),
    headers() {
      const headers = [
        { text: 'Status', value: 'status', align: 'left', width: 100 },
        { text: 'Submission date', value: 'createdAt', align: 'left', width: 160 },
        { text: 'Title', value: 'title', align: 'left', width: 180 },
        { text: 'Notes', value: 'notes', width: 90 },
        { text: 'Coaches', value: 'coachNames', width: 110 },
        { text: 'Creator', value: 'creator', align: 'left', width: 200 },
        { text: 'Project Unit', value: 'projectUnit', align: 'left', width: 130 }
      ];
      if (this.company.features[FEATURE_NAME.CAMPAIGNS]) {
        headers.push({ text: 'Campaign', value: 'campaign', align: 'left', width: 140 });
      }
      return headers;
    },
    noData() {
      return this.isLoading.projects ? 'Loading projects...' : 'No pending projects.';
    },
    exportDataFields() {
      const exportDataFields = {};
      this.headers.forEach((header) => {
        exportDataFields[header.text] = header.value;
      });
      exportDataFields.Status = { callback: (project) => this.getProjectStatus(project) };
      exportDataFields['Submission date'] = { callback: (project) => moment(project.createdAt).format('MMM DD, YYYY') };
      exportDataFields.Notes = { callback: (project) => project.notes.length };
      exportDataFields.Creator = { callback: (project) => project.creator?.name };
      if (this.company.features[FEATURE_NAME.CAMPAIGNS]) {
        exportDataFields.Campaign = { callback: (project) => project.campaign?.title };
      }
      exportDataFields['Project Unit'] = { callback: (project) => {
        if (project.projectUnit) return project.projectUnit.name;
        return null;
      } };
      return exportDataFields;
    },
    searchResult() {
      return this.projectsFilteredByUnit(this.pendingProjects)
        .filter((p) => this.customFilter(null, this.search, p));
    }
  },
  mounted() {
    // If a project id has been provided expand it
    this.$set(this.$refs.projectTable.expanded, this.projectId, true);
  },
  methods: {
    onClickRow(isExpandend, expand) {
      if (this.idle) {
        // eslint-disable-next-line no-param-reassign
        expand(!isExpandend);
      }
    },
    customFilter(value, search, item) {
      const customSearch = search.toString().toLowerCase();
      return (moment(item.createdAt).format('MMM DD, YYYY').toLowerCase().includes(customSearch)
        || item.title.toLowerCase().includes(customSearch)
        || item.notes.length.toString().toLowerCase().includes(customSearch)
        || item.coachNames.toLowerCase().includes(customSearch)
        || (item.creator && item.creator.name.toLowerCase().includes(customSearch))
        || (item.projectUnit && item.projectUnit.name
        && item.projectUnit.name.toLowerCase().includes(customSearch))
        || (item.campaign && item.campaign.title.toLowerCase().includes(customSearch)))
        && this.unitFilter(item);
    },
    customSort(items, sortProp, isDesc) {
      items.sort((self, other) => {
        let selfProp;
        let otherProp;
        // TODO: Remove workaround and find better solution
        if (sortProp[0] === 'projectUnit') {
          selfProp = self.projectUnit ? self.projectUnit.name : '';
          otherProp = other.projectUnit ? other.projectUnit.name : '';
        } else if (sortProp[0] === 'status') {
          selfProp = this.getProjectStatus(self);
          otherProp = this.getProjectStatus(other);
        } else {
          selfProp = self[sortProp[0]];
          otherProp = other[sortProp[0]];
        }
        if (selfProp === otherProp) {
          return 0;
        }
        return selfProp < otherProp ? -1 : 1;
      });
      return isDesc[0] ? items.reverse() : items;
    },
    getProjectStatus(project) {
      if (project.isApproved && project.isAlumni) {
        return 'Alumni';
      }
      return project.isApproved ? 'Approved' : 'In Review';
    }
  }
};
</script>

<style lang="scss" scoped>
  tr {
    cursor: pointer;
  }

  ::v-deep .disabled {
    color: gray;
  }

  .expanded {
    cursor: unset !important;
  }
</style>
