var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-10"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('download-excel',{attrs:{"data":_vm.searchResult,"fields":_vm.exportDataFields,"meta":[],"name":"download.xls"}},[_c('app-button',{staticClass:"mt-4 mt-md-0",attrs:{"secondary":"","disabled":_vm.searchResult.length === 0}},[_vm._v(" Export results ")])],1)],1),_c('unit-filter'),_c('v-data-table',{ref:"projectTable",staticClass:"table-container",attrs:{"headers":_vm.headers,"items":_vm.projectsFilteredByUnit(_vm.pendingProjects),"search":_vm.search,"custom-filter":_vm.customFilter,"custom-sort":_vm.customSort,"no-data-text":_vm.noData,"no-results-text":_vm.noSearchResultMessage,"single-expand":"","footer-props":{
        itemsPerPageText: 'Projects per page:',
        itemsPerPageOptions: [12, 24, 36, { text: 'All', value: -1 }]
      }},scopedSlots:_vm._u([(!_vm.pendingProjects.length && _vm.isLoading.projects)?{key:"body",fn:function(){return [_c('table-skeleton-loader',{attrs:{"headers":_vm.headers}})]},proxy:true}:{key:"item",fn:function(ref){
      var item = ref.item;
      var expand = ref.expand;
      var isExpanded = ref.isExpanded;
return [_c('tr',{class:{ disabled: !_vm.idle },on:{"click":function($event){return _vm.onClickRow(isExpanded, expand)}}},[_c('td',[_vm._v(_vm._s(_vm.getProjectStatus(item)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))]),_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(item.notes.length))]),_c('td',[_vm._v(_vm._s(item.coachNames))]),_c('td',[_vm._v(_vm._s(item.creator.name))]),_c('td',[(item.projectUnit)?_c('div',[_vm._v(" "+_vm._s(item.projectUnit.name)+" ")]):_vm._e()]),(_vm.company.features[_vm.FEATURE_NAME.CAMPAIGNS])?_c('td',[_vm._v(" "+_vm._s((item.campaign && item.campaign.title) || 'no campaign')+" ")]):_vm._e()])]}},{key:"expanded-item",fn:function(ref){
      var item = ref.item;
return [_c('tr',{staticClass:"expanded"},[_c('td',{staticClass:"pa-0",attrs:{"colspan":"7"}},[_c('pending-project',{ref:"project",attrs:{"project":item},on:{"idle":function($event){_vm.idle = $event; _vm.headers.forEach(function (h) { return h.sortable = $event; });}}})],1)])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }